import qs from 'qs';

import { PROFILE_DB_REF, NONPROFIT_DB_REF, TEAM_DB_REF } from '@/global/firebase';
import { MSG_PROFILE_NOT_FOUND } from '@/global/messages';
import {
  AVATAR_BUCKET,
  FIREBASE_CLOUD_FUNCTION,
  LOCAL_STORAGE_PREFIX,
  STRIPE_API_KEY,
  STRIPE_API_URL,
  FIREBASE_API_URL,
} from '@/global/environment';

import { HttpUtils, StringUtils } from '@/utils';

const STRIPE_CUSTOMER_API = `${STRIPE_API_URL}/v1/customers`;

class UserProvider {
  constructor(firebaseAuth, firebaseDatabase, firebaseStorage, dbPath) {
    this.auth = firebaseAuth;
    this.db = firebaseDatabase;
    this.storage = firebaseStorage;
    this.dbPath = dbPath;
  }

  getProfile = (uid) => this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}`).once('value');

  getProfilePath = (uid, path) => this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}/${path}`).once('value');

  getAgencyProfile = async (uid, agency) => {
    let snap;
    let isAgent = false;
    let nid = '';
    let name = '';
    let slugName = '';
    let img = null;
    const mAccess = { f_m_statement: true };

    if (agency) {
      snap = await this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}/${agency}`).once('value');

      if (snap.val()) {
        isAgent = true;
        nid = snap.val().key;
        name = snap.val().name;
        slugName = StringUtils.slugify(snap.val().name);
        img = snap.val().img;
        mAccess.f_m_statement = snap.val().hideFinancialManagement !== false;
      }
    } else {
      snap = await this.db
        .ref(`${this.dbPath}/${NONPROFIT_DB_REF}`)
        .orderByChild('agent')
        .equalTo(uid.toString())
        .limitToFirst(1)
        .once('value');

      if (snap.val()) {
        isAgent = true;
        snap.forEach((childSnap) => {
          nid = childSnap.val().key;
          name = childSnap.val().name;
          slugName = StringUtils.slugify(childSnap.val().name);
          img = childSnap.val().img;
          mAccess.f_m_statement = childSnap.val().hideFinancialManagement !== false;
        });
      }
    }

    return { isAgent, nid, name, slugName, img, mAccess };
  };

  getAuthUserProfile = (authUser) =>
    new Promise((resolve) => {
      if (this.auth.currentUser) {
        this.auth.currentUser.getIdToken(true).then((idToken) => {
          this.getProfile(authUser.uid).then((snapshot) => {
            if (snapshot.val()) {
              const dbProfile = {
                avatar: snapshot.val().avatar ? snapshot.val().avatar : null,
                firstName: snapshot.val().firstName,
                lastName: snapshot.val().lastName,
                location: snapshot.val().location ? snapshot.val().location : '',
                jobTitle: snapshot.val().jobTitle ? snapshot.val().jobTitle : '',
                scid: snapshot.val().scid ? snapshot.val().scid : null,
                uid: snapshot.val().uid,
                token: snapshot.val().token ? snapshot.val().token : null,
                social: snapshot.val().social ? snapshot.val().social : false,
                type: snapshot.val().type ? snapshot.val().type : null,
                roles: snapshot.val().roles ? snapshot.val().roles : {},
              };

              this.getAgencyProfile(authUser.uid, snapshot.val().agency).then((agencyProfile) => {
                if (agencyProfile.isAgent) {
                  dbProfile.agency = { ...agencyProfile };

                  // merge auth and db user
                  const authUserProfile = {
                    uid: authUser.uid,
                    displayName: authUser.displayName,
                    email: authUser.email,
                    emailVerified: authUser.emailVerified,
                    providerData: authUser.providerData,
                    photo: authUser.photoURL ? authUser.photoURL : null,
                    idToken,
                    ...dbProfile,
                  };

                  resolve(authUserProfile);
                }
              });
            }
          });
        });
      }
    });

  getAuthUserProfileOff = (authUser) => {
    this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${authUser.uid}`).off();
    this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}`).orderByChild('agent').equalTo(authUser.uid.toString()).off();
  };

  doVerifyAuthUser = (authUser) =>
    new Promise((resolve, reject) => {
      this.getProfile(authUser.uid).then((snapshot) => {
        if (!snapshot.val()) {
          return reject(new Error(MSG_PROFILE_NOT_FOUND));
        }

        const dbProfile = {
          email: authUser.email,
          firstName: snapshot.val().firstName,
          emailVerified: authUser.emailVerified,
          uid: snapshot.val().uid,
          token: snapshot.val().token ? snapshot.val().token : null,
          social: snapshot.val().social ? snapshot.val().social : false,
        };

        this.getAgencyProfile(authUser.uid, snapshot.val().agency).then((agencyProfile) => {
          if (agencyProfile.isAgent) {
            dbProfile.agency = { ...agencyProfile };
          }

          resolve(dbProfile);
        });

        return true;
      });
    });

  doVerifySocialUser = async (authUser) => {
    let profileInfo = null;
    const { uid } = authUser;

    const snaps = await this.getProfile(uid);
    if (snaps.val() === null) {
      profileInfo = await this.doRegisterSocialProfile(authUser);
    } else if (!snaps.val().emailVerified) {
      const updates = {};
      updates[`${this.dbPath}/${PROFILE_DB_REF}/${uid}/emailVerified`] = true;
      updates[`${this.dbPath}/${PROFILE_DB_REF}/${uid}/social`] = true;

      await this.db.ref().update(updates);
      await this.auth.currentUser.updateProfile({ emailVerified: true });
    }

    if (!profileInfo) {
      profileInfo = {
        email: snaps.val().email,
        firstName: snaps.val().firstName,
        emailVerified: true,
        uid: snaps.val().uid ? snaps.val().uid : uid,
        token: snaps.val().token,
        social: true,
      };
    }

    const agencyProfile = await this.getAgencyProfile(authUser.uid);
    if (agencyProfile.isAgent) {
      profileInfo.agency = { ...agencyProfile };
    }

    return profileInfo;
  };

  doSignOut = () => {
    localStorage.removeItem(LOCAL_STORAGE_PREFIX);
    this.auth.signOut();
  };

  doRegisterToken = (uid) =>
    new Promise((resolve) => {
      const token = Math.random().toString(36).slice(-8);
      this.db
        .ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}/token`)
        .set(token)
        .then(() => {
          resolve({ status: 'registered', token });
        });
    });

  doSendVerify = (profile, id = 1) =>
    new Promise((resolve, reject) => {
      const axiosConfig = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
      const mode = this.dbPath === 'dev' ? 'DEV' : 'PROD';
      const VERIFY_URL = `${FIREBASE_CLOUD_FUNCTION}/sendVerify`;
      const formData = {
        id,
        mode,
        data: JSON.stringify(profile),
        token: '8pS3d6GxECa9hNQd',
      };

      HttpUtils.postRequest(VERIFY_URL, qs.stringify(formData), axiosConfig)
        .then(() => {
          resolve({ status: 'send' });
        })
        .catch((err) => {
          reject(err.message);
        });
    });

  doRegisterStripe = (stripeData) => {
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${STRIPE_API_KEY}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return HttpUtils.postRequest(STRIPE_CUSTOMER_API, qs.stringify(stripeData), axiosConfig);
  };

  doRegisterNewUser = async (uid, data, userForm = 1) => {
    const { firstName, lastName, email, phone, payment, autoVerification } = data;

    await this.auth.currentUser.updateProfile({ displayName: `${firstName} ${lastName}` });

    const stripeData = {
      email,
      description: `Customer for ${email}`,
      metadata: {
        'first name': firstName,
        'last name': lastName,
        uid,
        phone,
        currency: 'USD',
      },
    };

    const res = await this.doRegisterStripe(stripeData);

    const token = Math.random().toString(36).slice(-8);
    const userProfile = {
      uid,
      scid: res.id,
      firstName,
      lastName,
      email,
      phone,
      token,
      emailVerified: autoVerification,
    };
    if (payment) {
      userProfile.payment = payment;
    }

    await this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}`).set(userProfile);

    // verify user
    if (autoVerification) {
      const idToken = await this.auth.currentUser.getIdToken(true);
      const VERIFY_API_URL = `${FIREBASE_API_URL}/v1/user/verify/${uid}`;
      await HttpUtils.getRequest(VERIFY_API_URL, { headers: { Authorization: `Bearer ${idToken}` } });
    } else {
      await this.doSendVerify(userProfile, userForm);
    }

    return { status: 'ok', uid };
  };

  doRegisterNewTeam = async (uid, data) => {
    const { firstName, lastName, email, agency, jobTitle, teamKey } = data;

    await this.auth.currentUser.updateProfile({ displayName: `${firstName} ${lastName}` });

    const stripeData = {
      email,
      description: `Customer for ${email}`,
      metadata: {
        'first name': firstName,
        'last name': lastName,
        uid,
        currency: 'USD',
      },
    };

    const res = await this.doRegisterStripe(stripeData);

    const token = Math.random().toString(36).slice(-8);
    const userProfile = {
      uid,
      scid: res.id,
      firstName,
      lastName,
      email,
      token,
      emailVerified: true,
      jobTitle,
      type: 'agency_user',
      agency,
    };

    await this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}`).set(userProfile);

    const updates = {};
    updates[`${this.dbPath}/${TEAM_DB_REF}/${agency}/${teamKey}/firstName`] = firstName;
    updates[`${this.dbPath}/${TEAM_DB_REF}/${agency}/${teamKey}/lastName`] = lastName;
    updates[`${this.dbPath}/${TEAM_DB_REF}/${agency}/${teamKey}/uid`] = uid;
    updates[`${this.dbPath}/${TEAM_DB_REF}/${agency}/${teamKey}/status`] = 'Active';
    await this.db.ref().update(updates);

    // verify user
    const idToken = await this.auth.currentUser.getIdToken(true);
    const VERIFY_API_URL = `${FIREBASE_API_URL}/v1/user/verify/${uid}`;
    await HttpUtils.getRequest(VERIFY_API_URL, { headers: { Authorization: `Bearer ${idToken}` } });

    return { status: 'ok', uid };
  };

  doRegisterSocialProfile = async (authUser) => {
    const { uid } = authUser;
    const fullName = authUser.displayName.split(' ');
    const stripeData = {
      email: authUser.email,
      description: `Customer for ${authUser.email}`,
      metadata: {
        'first name': fullName[0],
        'last name': fullName[1] ? fullName[1] : '',
        uid,
        phone: '',
        currency: 'USD',
      },
    };

    const stripeRes = await this.doRegisterStripe(stripeData);

    const userProfile = {
      uid,
      scid: stripeRes.id,
      firstName: fullName[0],
      lastName: fullName[1] ? fullName[1] : '',
      email: authUser.email,
      phone: '',
      emailVerified: true,
      social: true,
    };

    await this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}`).set(userProfile);
    await this.auth.currentUser.updateProfile({ emailVerified: true });

    return userProfile;
  };

  doUpdateUserProfile = async (data) => {
    const { uid, firstName, lastName, location, jobTitle, avatar, prevAvatar } = data;
    let avatarUrl = prevAvatar;

    const updates = {};
    updates[`${this.dbPath}/${PROFILE_DB_REF}/${uid}/firstName`] = firstName;
    updates[`${this.dbPath}/${PROFILE_DB_REF}/${uid}/lastName`] = lastName;
    updates[`${this.dbPath}/${PROFILE_DB_REF}/${uid}/location`] = location;
    updates[`${this.dbPath}/${PROFILE_DB_REF}/${uid}/jobTitle`] = jobTitle;

    if (avatar) {
      const avatarPath = `${AVATAR_BUCKET}/${uid}.jpg`;
      const avatarSnap = await this.storage.child(avatarPath).put(avatar, { contentType: avatar.type });
      avatarUrl = await avatarSnap.ref.getDownloadURL();
      updates[`${this.dbPath}/${PROFILE_DB_REF}/${uid}/avatar`] = avatarUrl;
    }

    await this.db.ref().update(updates);
    await this.auth.currentUser.updateProfile({ displayName: `${firstName} ${lastName}` });

    return { uid, firstName, lastName, location, jobTitle, avatar: avatarUrl };
  };

  getTeamList = (nid) => this.db.ref(`${this.dbPath}/${TEAM_DB_REF}/${nid}`);

  getActiveTeam = (nid) =>
    this.db.ref(`${this.dbPath}/${TEAM_DB_REF}/${nid}`).orderByChild('status').equalTo('Active').once('value');

  getTeamDetail = (nid, tid) => this.db.ref(`${this.dbPath}/${TEAM_DB_REF}/${nid}/${tid}`).once('value');

  doReinviteTeam = async (nid, tid) => {
    try {
      const mode = this.dbPath !== 'dev' ? 'prod' : this.dbPath;
      const idToken = await this.auth.currentUser.getIdToken(true);
      const axiosConfig = {
        headers: { Authorization: `Bearer ${idToken}`, 'Content-Type': 'application/json' },
      };
      const POST_API_URL = `${FIREBASE_API_URL}/v1/agency-user/reinvite/${mode}`;
      const payload = { agency: nid, teamKey: tid };

      await HttpUtils.postRequest(POST_API_URL, payload, axiosConfig);
    } catch (error) {
      console.log(error);
    }

    return { status: 'reinvite ok' };
  };

  doRemoveTeam = async (tid, nid, count) => {
    const userTeam = await this.db.ref(`${this.dbPath}/${TEAM_DB_REF}/${nid}/${tid}`).once('value');
    if (userTeam.val()) {
      try {
        const mode = this.dbPath !== 'dev' ? 'prod' : this.dbPath;
        const idToken = await this.auth.currentUser.getIdToken(true);
        const axiosConfig = {
          headers: { Authorization: `Bearer ${idToken}` },
        };
        const DELETE_API_URL = `${FIREBASE_API_URL}/v1/agency-user/${tid}/${nid}/${mode}`;

        await HttpUtils.deleteRequest(DELETE_API_URL, axiosConfig);
      } catch (error) {
        console.log(error);
      }

      const { uid } = userTeam.val();
      if (uid) {
        const userAvatar = await this.db.ref(`${this.dbPath}/${PROFILE_DB_REF}/${uid}/avatar`).once('value');
        if (userAvatar.val()) {
          try {
            const avatarPath = `${AVATAR_BUCKET}/${uid}.jpg`;
            await this.storage.child(avatarPath).delete();
          } catch (error) {
            console.log(error.message);
          }
        }
      }

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };
}

export default UserProvider;
