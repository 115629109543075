import {
  NONPROFIT_SET,
  NONPROFIT_CLEAR,
  NONPROFIT_UPDATE,
  CATEGORY_SET,
  CATEGORY_CLEAR,
  DEPARTMENT_CLEAR,
  DEPARTMENT_SET,
  AGENT_CLEAR,
  AGENT_SET,
} from './types';

const INITIAL_STATE = {
  detail: null,
  categories: null,
  departments: null,
  agent: null,
};

const applySetNonprofit = (state, action) => ({
  ...state,
  detail: action.nonprofit,
});

const applyClearNonprofit = (state) => ({
  ...state,
  detail: null,
});

const applyUpdateNonprofit = (state, action) => {
  const updatedData = {
    ...state.detail,
    name: action.name,
    street: action.street,
    city: action.city,
    countryState: action.countryState,
    zipCode: action.zipCode,
    ein: action.ein,
    category: action.category,
    type: action.type,
    img: action.img,
    email: action.email,
    phone: action.phone,
    website: action.website,
    description: action.description,
  };

  return {
    ...state,
    detail: updatedData,
  };
};

const applySetCategories = (state, action) => ({
  ...state,
  categories: action.categories,
});

const applyClearCategories = (state) => ({
  ...state,
  categories: null,
});

const applySetDepartments = (state, action) => ({
  ...state,
  departments: action.departments,
});

const applyClearDepartments = (state) => ({
  ...state,
  departments: null,
});

const applySetAgent = (state, action) => ({
  ...state,
  agent: action.agent,
});

const applyClearAgent = (state) => ({
  ...state,
  agent: null,
});

function nonprofitReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NONPROFIT_SET:
      return applySetNonprofit(state, action);
    case NONPROFIT_CLEAR:
      return applyClearNonprofit(state);
    case NONPROFIT_UPDATE:
      return applyUpdateNonprofit(state, action.payload);
    case CATEGORY_SET:
      return applySetCategories(state, action);
    case CATEGORY_CLEAR:
      return applyClearCategories(state);
    case DEPARTMENT_SET:
      return applySetDepartments(state, action);
    case DEPARTMENT_CLEAR:
      return applyClearDepartments(state);
    case AGENT_SET:
      return applySetAgent(state, action);
    case AGENT_CLEAR:
      return applyClearAgent(state);
    default:
      return state;
  }
}

export default nonprofitReducer;
