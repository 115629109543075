import { GRANT_FUNDER_DB_REF } from '@/global/firebase';

class GrantProvider {
  constructor(firebaseDatabase, dbPath) {
    this.db = firebaseDatabase;
    this.dbPath = dbPath;
  }

  // * FUNDER DB
  getFunderList = nid => this.db.ref(`${this.dbPath}/${GRANT_FUNDER_DB_REF}/${nid}`);

  getFunderListOptions = nid =>
    new Promise(resolve => {
      this.getFunderList(nid)
        .once('value')
        .then(snapshot => {
          const funderList = [];
          snapshot.forEach(childSnapshot => {
            funderList.push({ value: childSnapshot.key, label: childSnapshot.val().name });
          });

          if (funderList.length > 0) {
            funderList.reverse();
          }

          resolve({ funderList });
        });
    });

  doSaveFunder = async (nid, data, returnList = false) => {
    const { key, ...restData } = data;
    let id = key;

    if (id) {
      await this.db.ref(`${this.dbPath}/${GRANT_FUNDER_DB_REF}/${nid}/${id}`).set(restData);
    } else {
      id = this.db.ref(`${this.dbPath}/${GRANT_FUNDER_DB_REF}/${nid}`).push(restData).key;
    }

    const result = { value: id, label: restData.name };
    if (returnList) {
      const { funderList } = await this.getFunderListOptions(nid);
      result.funderList = funderList;
    }

    return result;
  };

  doRemoveFunder = async (nid, id, count) => {
    const funder = await this.db.ref(`${this.dbPath}/${GRANT_FUNDER_DB_REF}/${nid}/${id}`).once('value');
    if (funder.val()) {
      await this.db.ref(`${this.dbPath}/${GRANT_FUNDER_DB_REF}/${nid}/${id}`).remove();

      const { funderList } = await this.getFunderListOptions(nid);

      return { status: 'deleted', count, funderList };
    }

    return { status: 'not found' };
  };
}

export default GrantProvider;
