export const WEBSITE_TITLE = process.env.REACT_APP_WEBSITE_TITLE;
export const WEBSITE_NAME = process.env.REACT_APP_WEBSITE_NAME;
export const WEBSITE_DESCRIPTION = process.env.REACT_APP_WEBSITE_DESCRIPTION;
export const WEBSITE_KEYWORDS = process.env.REACT_APP_WEBSITE_KEYWORDS;
export const OPEN_GRAPH = process.env.REACT_APP_OPEN_GRAPH;
export const WEBFLOW_SITE = process.env.REACT_APP_WEBFLOW_SITE;
export const LOCAL_STORAGE_PREFIX = process.env.REACT_APP_LOCAL_STORAGE_PREFIX;
export const MAIN_WEB_URL = process.env.REACT_APP_MAIN_WEB_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const DONATION_WEB_URL = process.env.REACT_APP_DONATION_WEB_URL;
export const BUDGETING_PATH = process.env.REACT_APP_BUDGETING_PATH;
export const FUNDING_PATH = process.env.REACT_APP_FUNDING_PATH;

// firebase
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_CLOUD_FUNCTION = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTION;
export const FIREBASE_DB_PATH = process.env.REACT_APP_FIREBASE_DB_PATH;
export const CONFIRMATION_EMAIL_REDIRECT = process.env.REACT_APP_FIREBASE_CONFIRMATION_EMAIL_REDIRECT;
export const NONPROFIT_LOGO_BUCKET = process.env.REACT_APP_FIREBASE_NONPROFIT_LOGO_BUCKET;
export const NONPROFIT_COVER_BUCKET = process.env.REACT_APP_FIREBASE_NONPROFIT_COVER_BUCKET;
export const PROGRAMS_COVER_BUCKET = process.env.REACT_APP_FIREBASE_PROGRAMS_COVER_BUCKET;
export const METRICS_COVER_BUCKET = process.env.REACT_APP_FIREBASE_METRICS_COVER_BUCKET;
export const CAMPAIGN_COVER_BUCKET = process.env.REACT_APP_FIREBASE_CAMPAIGN_COVER_BUCKET;
export const AVATAR_BUCKET = process.env.REACT_APP_FIREBASE_AVATAR_BUCKET;
export const CAMPAIGN_PREFIX = process.env.REACT_APP_FIREBASE_CAMPAIGN_PREFIX;
export const FIREBASE_API_URL = process.env.REACT_APP_FIREBASE_API_URL;

// stripe
export const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
export const STRIPE_API_URL = process.env.REACT_APP_STRIPE_API_URL;
