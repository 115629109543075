import { METRIC_DB_REF, METRIC_CAT_TYPE_DB_REF, OUTCOME_DB_REF } from '@/global/firebase';
import { METRICS_COVER_BUCKET } from '@/global/environment';

import { StringUtils } from '@/utils';

class MetricProvider {
  constructor(firebaseDatabase, firebaseStorage, dbPath) {
    this.db = firebaseDatabase;
    this.storage = firebaseStorage;
    this.dbPath = dbPath;
  }

  getDetail = (nid, mid) => this.db.ref(`${this.dbPath}/${METRIC_DB_REF}/${nid}/${mid}`);

  getDetailPath = (nid, mid, path) => this.db.ref(`${this.dbPath}/${METRIC_DB_REF}/${nid}/${mid}/${path}`);

  getList = (nid) => this.db.ref(`${this.dbPath}/${METRIC_DB_REF}/${nid}`);

  getListOptions = (nid) =>
    new Promise((resolve) => {
      this.getList(nid)
        .once('value')
        .then((snapshot) => {
          const metrics = [];
          snapshot.forEach((childSnapshot) => {
            metrics.push({ id: childSnapshot.key, name: childSnapshot.val().name });
          });

          if (metrics.length > 0) {
            metrics.reverse();
          }

          resolve({ metrics });
        });
    });

  doSaveMetric = async (nid, data) => {
    const { key, cover, prevCover, ...restData } = data;
    let mid = key;
    let coverUrl = prevCover || null;

    if (mid) {
      await this.db.ref(`${this.dbPath}/${METRIC_DB_REF}/${nid}/${mid}`).set(restData);
    } else {
      mid = this.db.ref(`${this.dbPath}/${METRIC_DB_REF}/${nid}`).push(restData).key;
    }

    if (cover) {
      const coverPath = `${METRICS_COVER_BUCKET}/${mid}.jpg`;
      const coverSnap = await this.storage.child(coverPath).put(cover, { contentType: cover.type });
      coverUrl = await coverSnap.ref.getDownloadURL();
    }

    await this.db.ref(`${this.dbPath}/${METRIC_DB_REF}/${nid}/${mid}/cover`).set(coverUrl);

    return { ...data, slug: StringUtils.slugify(data.name), cover: coverUrl };
  };

  doRemoveMetric = async (nid, mid, count) => {
    const metric = await this.db.ref(`${this.dbPath}/${METRIC_DB_REF}/${nid}/${mid}`).once('value');
    if (metric.val()) {
      await this.db.ref(`${this.dbPath}/${METRIC_DB_REF}/${nid}/${mid}`).remove();

      try {
        const coverPath = `${METRICS_COVER_BUCKET}/${mid}.jpg`;
        await this.storage.child(coverPath).delete();
      } catch (error) {
        console.log(error.message);
      }

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };

  getCatTypeList = (nid) => this.db.ref(`${this.dbPath}/${METRIC_CAT_TYPE_DB_REF}/${nid}`);

  doSaveCatTypeList = (nid, mid, data) =>
    this.db.ref(`${this.dbPath}/${METRIC_CAT_TYPE_DB_REF}/${nid}/${mid}`).set(data);

  doRemoveCatTypeList = async (nid, mid, count) => {
    const metric = await this.db.ref(`${this.dbPath}/${METRIC_CAT_TYPE_DB_REF}/${nid}/${mid}`).once('value');
    if (metric.val()) {
      await this.db.ref(`${this.dbPath}/${METRIC_CAT_TYPE_DB_REF}/${nid}/${mid}`).remove();

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };

  getDetailOutcome = (nid, id) => this.db.ref(`${this.dbPath}/${OUTCOME_DB_REF}/${nid}/${id}`);

  getListOutcome = (nid) => this.db.ref(`${this.dbPath}/${OUTCOME_DB_REF}/${nid}`);

  doSaveOutcome = async (nid, data) => {
    const { key, ...restData } = data;
    let id = key;

    if (id) {
      await this.db.ref(`${this.dbPath}/${OUTCOME_DB_REF}/${nid}/${id}`).set(restData);
    } else {
      id = this.db.ref(`${this.dbPath}/${OUTCOME_DB_REF}/${nid}`).push(restData).key;
    }

    return { ...data, slug: StringUtils.slugify(data.name) };
  };

  doRemoveOutcome = async (nid, id, count) => {
    const outcome = await this.db.ref(`${this.dbPath}/${OUTCOME_DB_REF}/${nid}/${id}`).once('value');
    if (outcome.val()) {
      await this.db.ref(`${this.dbPath}/${OUTCOME_DB_REF}/${nid}/${id}`).remove();

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };
}

export default MetricProvider;
