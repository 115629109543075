import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LOCAL_STORAGE_PREFIX } from '@/global/environment';

import { setAuthUserAction } from '@/services/auth/actions';
import {
  setNonprofitAction,
  setCategoriesAction,
  setDepartmentsAction,
  setAgentAction,
} from '@/services/nonprofits/actions';
import * as GrantActions from '@/services/grant/actions';

import { FirebaseContext } from '../Firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      const fundraProfile = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PREFIX));
      const { onSetAuthUser } = this.props;
      onSetAuthUser(fundraProfile);
    }

    componentDidMount() {
      const firebase = this.context;
      const {
        onSetAuthUser,
        onSetNonprofit,
        onSetCategories,
        onSetDepartments,
        onSetGrantFunder,
        onSetAgent,
      } = this.props;

      this.listener = firebase.onAuthUserListener(
        (authUser) => {
          localStorage.setItem(LOCAL_STORAGE_PREFIX, JSON.stringify(authUser));
          onSetAuthUser(authUser);

          firebase.nonprofitProvider.getDetail(authUser.agency.nid).then((snapshot) => {
            const nonprofit = {
              key: snapshot.val().key,
              name: snapshot.val().name,
              street: snapshot.val().address,
              city: snapshot.val().city,
              countryState: snapshot.val().state,
              zipCode: snapshot.val().zipcode,
              ein: snapshot.val().federal,
              category: snapshot.val().category,
              type: snapshot.val().type,
              img: snapshot.val().img,
              email: snapshot.val().contact ? snapshot.val().contact.email : '',
              phone: snapshot.val().contact ? snapshot.val().contact.phone : '',
              website: snapshot.val().website,
              description: snapshot.val().description,
              subscribe: snapshot.val().subscribe || null,
              stripeAccount: snapshot.val().stripe_account || null,
            };

            onSetNonprofit(nonprofit);

            firebase.userProvider.getProfile(snapshot.val().agent).then((result) => {
              if (result.val()) {
                const { uid, firstName, lastName, email, jobTitle, avatar } = result.val();
                onSetAgent({ uid, firstName, lastName, email, jobTitle, avatar });
              }
            });

            firebase.departmentProvider.getListOptions(authUser.agency.nid).then((result) => {
              onSetDepartments(result.departments);
            });

            firebase.grantProvider.getFunderListOptions(authUser.agency.nid).then((result) => {
              onSetGrantFunder(result.funderList);
            });
          });

          firebase.nonprofitProvider.getCategoryOptions().then((result) => {
            onSetCategories(result.categories);
          });
        },
        () => {
          localStorage.removeItem(LOCAL_STORAGE_PREFIX);
          onSetAuthUser(null);
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component {...this.props} />;
    }
  }

  WithAuthentication.contextType = FirebaseContext;

  WithAuthentication.propTypes = {
    onSetAuthUser: PropTypes.func.isRequired,
    onSetNonprofit: PropTypes.func.isRequired,
    onSetCategories: PropTypes.func.isRequired,
    onSetDepartments: PropTypes.func.isRequired,
    onSetGrantFunder: PropTypes.func.isRequired,
    onSetAgent: PropTypes.func.isRequired,
  };

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch(setAuthUserAction(authUser)),
    onSetNonprofit: (nonprofit) => dispatch(setNonprofitAction(nonprofit)),
    onSetCategories: (categories) => dispatch(setCategoriesAction(categories)),
    onSetDepartments: (departments) => dispatch(setDepartmentsAction(departments)),
    onSetGrantFunder: (funder) => dispatch(GrantActions.setGrantFunderAction(funder)),
    onSetAgent: (agent) => dispatch(setAgentAction(agent)),
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
