import { BUDGETING_DB_REF } from '@/global/firebase';

class BudgetingProvider {
  constructor(firebaseDatabase, dbPath) {
    this.db = firebaseDatabase;
    this.dbPath = dbPath;
  }

  getDetail = (nid, bid) => this.db.ref(`${this.dbPath}/${BUDGETING_DB_REF}/${nid}/${bid}`).once('value');

  getList = nid => this.db.ref(`${this.dbPath}/${BUDGETING_DB_REF}/${nid}`);

  getListFinal = nid =>
    this.db
      .ref(`${this.dbPath}/${BUDGETING_DB_REF}/${nid}`)
      .orderByChild('finalized')
      .equalTo(true);
}

export default BudgetingProvider;
