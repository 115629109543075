import { combineReducers } from 'redux';
import coreReducer from '@/services/core/reducer';
import sessionReducer from '@/services/auth/reducer';
import nonprofitReducer from '@/services/nonprofits/reducer';
import grantReducer from '@/services/grant/reducer';

const rootReducer = combineReducers({
  coreState: coreReducer,
  sessionState: sessionReducer,
  nonprofitState: nonprofitReducer,
  grantState: grantReducer,
});

export default rootReducer;
