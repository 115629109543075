export const PROFILE_DB_REF = 'profile';
export const TEAM_DB_REF = 'team';
export const NONPROFIT_DB_REF = 'nonprofit';
export const NONPROFIT_REQUESTED_DB_REF = 'nonprofit_requested';
export const CAMPAIGN_DB_REF = 'campaigns';
export const CATEGORY_DB_REF = 'category';
export const PROGRAM_DB_REF = 'nonprofit_programs';
export const METRIC_DB_REF = 'nonprofit_metrics';
export const OUTCOME_DB_REF = 'nonprofit_outcomes';
export const METRIC_CAT_TYPE_DB_REF = 'metric_category';
export const STRATEGIC_CORE_VALUES_DB_REF = 'strategic_core_values';
export const STRATEGIC_GOAL_DB_REF = 'strategic_goals';
export const STRATEGIC_OBJECTIVES_DB_REF = 'strategic_objectives';
export const STRATEGIC_PLANNING_DB_REF = 'strategic_planning';
export const STRATEGIC_ACTION_PLAN_DB_REF = 'strategic_action_plans';
export const BUDGETING_DB_REF = 'budgeting';
export const DEPARTMENT_DB_REF = 'departments';
export const GRANT_FUNDER_DB_REF = 'grant_funder';
export const SETTING_DB_REF = 'setting';
export const SUBSCRIPTION_PACKAGE_DB_REF = 'subscription_packages';
