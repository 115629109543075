import { FUNDER_SET, FUNDER_CLEAR } from './types';

const INITIAL_STATE = {
  funder: null,
};

const applySetFunder = (state, action) => ({
  ...state,
  funder: action.funder,
});

const applyClearFunder = (state) => ({
  ...state,
  funder: null,
});

function grantReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FUNDER_SET:
      return applySetFunder(state, action);
    case FUNDER_CLEAR:
      return applyClearFunder(state);
    default:
      return state;
  }
}

export default grantReducer;
