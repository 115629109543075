import { clearSuccessAction, setSuccessAction, clearErrorAction, setErrorAction } from '../core/actions';
import { updateAgencyAction } from '../auth/actions';

import {
  NONPROFIT_SET,
  NONPROFIT_CLEAR,
  NONPROFIT_UPDATE,
  CATEGORY_SET,
  CATEGORY_CLEAR,
  DEPARTMENT_SET,
  DEPARTMENT_CLEAR,
  AGENT_SET,
  AGENT_CLEAR,
} from './types';
import { MSG_NONPROFIT_UPDATED } from '@/global/messages';

export const setNonprofitAction = (nonprofit) => ({
  type: NONPROFIT_SET,
  nonprofit,
});
export const clearNonprofitAction = () => ({ type: NONPROFIT_CLEAR });
export const updateDetailAction = (payload) => ({ type: NONPROFIT_UPDATE, payload });
export const setCategoriesAction = (categories) => ({
  type: CATEGORY_SET,
  categories,
});
export const clearCategoriesAction = () => ({ type: CATEGORY_CLEAR });
export const updateNonprofitAction = (payload, firebase) => {
  return (dispatch, getState) => {
    dispatch(clearSuccessAction());
    dispatch(clearErrorAction());

    return firebase.nonprofitProvider.doUpdateDetail(payload.key, payload).then(
      (result) => {
        dispatch(updateAgencyAction(result));
        dispatch(updateDetailAction(result));
        dispatch(setSuccessAction(MSG_NONPROFIT_UPDATED));

        localStorage.setItem('fundra-profile', JSON.stringify(getState().sessionState.authUser));
      },
      (error) => dispatch(setErrorAction(error.message)),
    );
  };
};
export const setDepartmentsAction = (departments) => ({
  type: DEPARTMENT_SET,
  departments,
});
export const clearDepartmentsAction = () => ({ type: DEPARTMENT_CLEAR });
export const setAgentAction = (agent) => ({
  type: AGENT_SET,
  agent,
});
export const clearAgentAction = () => ({ type: AGENT_CLEAR });
