export const LANDING = '/';
export const SIGN_UP = '/signup';
export const JOIN_TEAM = '/join-team';
export const SIGN_IN = '/signin';
export const SIGN_IN_WITH_EMAIL_LINK = '/signin-with-email-link';
export const ACCOUNT = '/account';
export const ACCOUNT_CHANGE_PASSWORD = '/account/change-password';
export const PASSWORD_FORGET = '/forget-password';
export const NONPROFIT = '/organization';
export const NONPROFIT_TEAM = '/organization/teams';
export const STRATEGIC_PLANNING = '/strategic-planning';
export const STRATEGIC_PLANNING_VISION = '/strategic-planning/vision-statement';
export const STRATEGIC_PLANNING_MISSION = '/strategic-planning/mission-statement';
export const STRATEGIC_PLANNING_CORE = '/strategic-planning/core-values';
export const STRATEGIC_PLANNING_SWOT = '/strategic-planning/swot-analysis';
export const STRATEGIC_PLANNING_LONG_TERM = '/strategic-planning/long-term-goals';
export const STRATEGIC_PLANNING_YEARLY_OBJECTIVE = '/strategic-planning/yearly-objectives';
export const STRATEGIC_PLANNING_ACTION_PLAN = '/strategic-planning/action-plans';
export const OPERATIONAL_PLANNING = '/operational-planning';
export const OPERATIONAL_PLANNING_PROGRAMS = '/operational-planning/programs';
export const OPERATIONAL_PLANNING_DEPARTMENT = '/operational-planning/departments';
export const OPERATIONAL_PLANNING_ACTIVITIES = '/operational-planning/activities';
export const OPERATIONAL_PLANNING_OUTCOMES = '/operational-planning/outcomes';
export const CAMPAIGNS = '/campaigns';
export const METRICS_CATEGORY_TYPE = '/metrics/category-type';
export const GRANT_FUNDER = '/grant-funder';
export const GRANT_MANAGEMENT = '/grant-management';
export const GRANT_MANAGEMENT_PROJECTS = '/grant-management/projects/:id';
export const GRANT_MANAGEMENT_ACTIVITIES = '/grant-management/projects/:project/activities/:id';
export const ANALYZE_ANNUAL_REPORTING = '/reports/annual';
