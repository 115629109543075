import {
  CATEGORY_DB_REF,
  NONPROFIT_DB_REF,
  PROGRAM_DB_REF,
  SUBSCRIPTION_PACKAGE_DB_REF,
  NONPROFIT_REQUESTED_DB_REF,
  PROFILE_DB_REF,
} from '@/global/firebase';
import { NONPROFIT_LOGO_BUCKET, NONPROFIT_COVER_BUCKET, PROGRAMS_COVER_BUCKET } from '@/global/environment';

import { DateTimeUtils, StringUtils } from '@/utils';

class NonprofitProvider {
  constructor(firebaseDatabase, firebaseStorage, dbPath) {
    this.db = firebaseDatabase;
    this.storage = firebaseStorage;
    this.dbPath = dbPath;
  }

  getDetail = (nid) => this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}`).once('value');

  getDetailPath = (nid, path) => this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/${path}`);

  getPrograms = (nid) => this.db.ref(`${this.dbPath}/${PROGRAM_DB_REF}/${nid}`);

  getCategory = () => this.db.ref(`${this.dbPath}/${CATEGORY_DB_REF}`);

  getTypeByCategory = (cid) => this.db.ref(`${this.dbPath}/${CATEGORY_DB_REF}/${cid}/types`);

  getCategoryOptions = () =>
    new Promise((resolve) => {
      this.db
        .ref(`${this.dbPath}/${CATEGORY_DB_REF}`)
        .once('value')
        .then((snapshot) => {
          const categories = [];
          snapshot.forEach((childSnapshot) => {
            const types = [];
            Object.keys(childSnapshot.val().types).forEach((key) => {
              types.push({
                id: childSnapshot.val().types[key].id,
                name: childSnapshot.val().types[key].name,
              });
            });

            categories.push({ id: childSnapshot.val().id, name: childSnapshot.val().name, types });
          });

          resolve({ categories });
        });
    });

  doInsertNew = async (data) => {
    const {
      email,
      organizationName,
      organizationCategory,
      organizationType,
      logo,
      defaultPackage,
      autoVerification,
      uid,
    } = data;

    let subscribe = null;
    const packageDetail = await this.db
      .ref(`${this.dbPath}/${SUBSCRIPTION_PACKAGE_DB_REF}/${defaultPackage}`)
      .once('value');
    if (packageDetail.val()) {
      subscribe = { ...packageDetail.val(), paymentMethod: 'monthly' };
    }

    const newPostKey = DateTimeUtils.getCurrentTimeMs();
    const nonprofitData = {
      agent: uid,
      name: organizationName,
      address: '',
      city: '',
      state: '',
      zipcode: '',
      federal: '',
      category: organizationCategory,
      type: organizationType,
      contact: { email: '', phone: '' },
      website: '',
      description: '',
      time: newPostKey,
      key: newPostKey,
      subscribe,
    };

    if (autoVerification) {
      await this.db.ref(`${this.dbPath}/${NONPROFIT_DB_REF}/${newPostKey}`).set(nonprofitData);
    } else {
      const updates = {};
      updates[`${this.dbPath}/${NONPROFIT_REQUESTED_DB_REF}/${newPostKey}`] = nonprofitData;
      updates[`${this.dbPath}/${PROFILE_DB_REF}/${uid}/nonprofit/requested/${newPostKey}`] = {
        key: newPostKey,
        time: newPostKey,
      };

      await this.db.ref().update(updates);

      localStorage.setItem('fundra-app-emailForSignIn', email);
    }

    if (logo) {
      const logoPath = `${NONPROFIT_LOGO_BUCKET}/${newPostKey}.jpg`;
      const logoSnap = await this.storage.child(logoPath).put(logo, { contentType: logo.type });
      const logoUrl = await logoSnap.ref.getDownloadURL();
      const dbTarget = autoVerification ? NONPROFIT_DB_REF : NONPROFIT_REQUESTED_DB_REF;
      await this.db.ref(`${this.dbPath}/${dbTarget}/${newPostKey}/img/logo`).set(logoUrl);
    }

    return { status: 'ok', newPostKey };
  };

  doUpdateDetail = async (nid, data) => {
    const { logo, prevLogo, cover, prevCover } = data;
    let logoUrl = prevLogo;
    let coverUrl = prevCover;

    const updates = {};
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/name`] = data.name;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/address`] = data.street;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/city`] = data.city;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/state`] = data.countryState;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/zipcode`] = data.zipCode;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/federal`] = data.ein;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/category`] = data.category;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/type`] = data.type;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/website`] = data.website;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/contact/email`] = data.email;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/contact/phone`] = data.phone;
    updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/description`] = data.description;

    if (logo) {
      const logoPath = `${NONPROFIT_LOGO_BUCKET}/${nid}.jpg`;
      const logoSnap = await this.storage.child(logoPath).put(logo, { contentType: logo.type });
      logoUrl = await logoSnap.ref.getDownloadURL();
      updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/img/logo`] = logoUrl;
    }

    if (cover) {
      const coverPath = `${NONPROFIT_COVER_BUCKET}/${nid}.jpg`;
      const coverSnap = await this.storage.child(coverPath).put(cover, { contentType: cover.type });
      coverUrl = await coverSnap.ref.getDownloadURL();
      updates[`${this.dbPath}/${NONPROFIT_DB_REF}/${nid}/img/cover`] = coverUrl;
    }

    await this.db.ref().update(updates);

    return { ...data, slug: StringUtils.slugify(data.name), img: { logo: logoUrl, cover: coverUrl } };
  };

  doInsertProgram = async (nid, data) => {
    const { cover } = data;
    const pid = DateTimeUtils.getCurrentTimeMs();
    const programData = { id: pid, ...data };
    await this.db.ref(`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}`).set(programData);

    if (cover) {
      const coverPath = `${PROGRAMS_COVER_BUCKET}/${pid}.jpg`;
      const coverSnap = await this.storage.child(coverPath).put(cover, { contentType: cover.type });
      const coverUrl = await coverSnap.ref.getDownloadURL();

      await this.db.ref(`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}/cover`).set(coverUrl);
    }

    return { pid };
  };

  doUpdateProgram = async (nid, pid, data) => {
    const { cover, prevCover } = data;
    let coverUrl = prevCover;

    const updates = {};
    updates[`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}/id`] = Number.parseInt(pid, 10);
    updates[`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}/name`] = data.name;
    updates[`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}/description`] = data.description;
    updates[`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}/programLead`] = data.programLead;
    updates[`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}/strategicGoal`] = data.strategicGoal;
    updates[`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}/department`] = data.department;

    if (cover) {
      const coverPath = `${PROGRAMS_COVER_BUCKET}/${pid}.jpg`;
      const coverSnap = await this.storage.child(coverPath).put(cover, { contentType: cover.type });
      coverUrl = await coverSnap.ref.getDownloadURL();
      updates[`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}/cover`] = coverUrl;
    }

    await this.db.ref().update(updates);

    return { ...data, slug: StringUtils.slugify(data.name), cover: coverUrl };
  };

  doRemoveProgram = async (nid, pid, count) => {
    const program = await this.db.ref(`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}`).once('value');
    if (program.val()) {
      await this.db.ref(`${this.dbPath}/${PROGRAM_DB_REF}/${nid}/${pid}`).remove();

      try {
        const coverPath = `${PROGRAMS_COVER_BUCKET}/${pid}.jpg`;
        await this.storage.child(coverPath).delete();
      } catch (error) {
        console.log(error.message);
      }

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };
}

export default NonprofitProvider;
