import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import * as ROUTES from '@/global/routes';
import { LOCAL_STORAGE_PREFIX } from '@/global/environment';

import { clearAuthUserAction } from '@/services/auth/actions';
import {
  clearNonprofitAction,
  clearCategoriesAction,
  clearDepartmentsAction,
  clearAgentAction,
} from '@/services/nonprofits/actions';
import * as GrantActions from '@/services/grant/actions';
import { FirebaseContext } from '../Firebase';

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      const firebase = this.context;
      this.listener = firebase.onAuthUserListener(
        (authUser) => {
          if (!condition(authUser)) {
            this.clearAllState();
          }
        },
        () => this.clearAllState(),
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    clearAllState() {
      const {
        history,
        onClearAuthUser,
        onClearNonprofit,
        onClearCategories,
        onClearDepartments,
        onClearGrantFunder,
        onClearAgent,
      } = this.props;
      localStorage.removeItem(LOCAL_STORAGE_PREFIX);
      onClearAuthUser();
      onClearNonprofit();
      onClearCategories();
      onClearDepartments();
      onClearGrantFunder();
      onClearAgent();

      history.push(ROUTES.SIGN_IN);
    }

    render() {
      const { authUser } = this.props;
      // eslint-disable-next-line react/jsx-props-no-spreading
      return condition(authUser) ? <Component {...this.props} /> : null;
    }
  }

  WithAuthorization.contextType = FirebaseContext;

  WithAuthorization.propTypes = {
    authUser: PropTypes.instanceOf(Object),
    history: PropTypes.instanceOf(Object).isRequired,
    onClearAuthUser: PropTypes.func.isRequired,
    onClearNonprofit: PropTypes.func.isRequired,
    onClearCategories: PropTypes.func.isRequired,
    onClearDepartments: PropTypes.func.isRequired,
    onClearGrantFunder: PropTypes.func.isRequired,
    onClearAgent: PropTypes.func.isRequired,
  };
  WithAuthorization.defaultProps = { authUser: null };

  const mapStateToProps = (state) => ({
    authUser: state.sessionState.authUser,
  });

  const mapDispatchToProps = (dispatch) => ({
    onClearAuthUser: () => dispatch(clearAuthUserAction()),
    onClearNonprofit: () => dispatch(clearNonprofitAction()),
    onClearCategories: () => dispatch(clearCategoriesAction()),
    onClearDepartments: () => dispatch(clearDepartmentsAction()),
    onClearGrantFunder: () => dispatch(GrantActions.clearGrantFunderAction()),
    onClearAgent: () => dispatch(clearAgentAction()),
  });

  return compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(WithAuthorization);
};

export default withAuthorization;
