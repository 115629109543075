import { SETTING_DB_REF } from '@/global/firebase';

class SettingProvider {
  constructor(firebaseDatabase, dbPath) {
    this.db = firebaseDatabase;
    this.dbPath = dbPath;
  }

  getValue = (path) => this.db.ref(`${this.dbPath}/${SETTING_DB_REF}/${path}`).once('value');

  getDetail = (path, id) => this.db.ref(`${this.dbPath}/${SETTING_DB_REF}/${path}/${id}`).once('value');

  getList = (path) => this.db.ref(`${this.dbPath}/${SETTING_DB_REF}/${path}`);

  getListOptions = (path) =>
    new Promise((resolve) => {
      this.getList(path)
        .once('value')
        .then((snapshot) => {
          const settings = [];
          snapshot.forEach((childSnapshot) => {
            settings.push({ id: childSnapshot.key, ...childSnapshot.val() });
          });

          resolve({ settings });
        });
    });
}

export default SettingProvider;
