import { DEPARTMENT_DB_REF } from '@/global/firebase';

class DepartmentProvider {
  constructor(firebaseDatabase, dbPath) {
    this.db = firebaseDatabase;
    this.dbPath = dbPath;
  }

  getDetail = (nid, id) => this.db.ref(`${this.dbPath}/${DEPARTMENT_DB_REF}/${nid}/${id}`).once('value');

  getList = (nid) => this.db.ref(`${this.dbPath}/${DEPARTMENT_DB_REF}/${nid}`);

  getListOptions = (nid) =>
    new Promise((resolve) => {
      this.getList(nid)
        .once('value')
        .then((snapshot) => {
          const departments = [];
          snapshot.forEach((childSnapshot) => {
            departments.push({ id: childSnapshot.key, ...childSnapshot.val() });
          });

          if (departments.length > 0) {
            departments.reverse();
          }

          resolve({ departments });
        });
    });

  doSave = async (nid, data) => {
    const { key, ...restData } = data;
    let id = key;

    if (id) {
      await this.db.ref(`${this.dbPath}/${DEPARTMENT_DB_REF}/${nid}/${id}`).set(restData);
    } else {
      id = this.db.ref(`${this.dbPath}/${DEPARTMENT_DB_REF}/${nid}`).push(restData).key;
    }

    return { id, ...data };
  };

  doRemove = async (nid, id, count) => {
    const department = await this.db.ref(`${this.dbPath}/${DEPARTMENT_DB_REF}/${nid}/${id}`).once('value');
    if (department.val()) {
      await this.db.ref(`${this.dbPath}/${DEPARTMENT_DB_REF}/${nid}/${id}`).remove();

      const listOptions = await this.getListOptions(nid);

      return { status: 'deleted', count, departments: listOptions.departments };
    }

    return { status: 'not found' };
  };
}

export default DepartmentProvider;
