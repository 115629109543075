import { clearErrorAction, setErrorAction } from '../core/actions';
import { FUNDER_CLEAR, FUNDER_SET } from './types';

export const setGrantFunderAction = (funder) => ({
  type: FUNDER_SET,
  funder,
});
export const clearGrantFunderAction = () => ({ type: FUNDER_CLEAR });

export const saveGrantFunderAction = (payload, firebase) => {
  return (dispatch) => {
    const { nid, ...restData } = payload;

    dispatch(clearErrorAction());

    return firebase.grantProvider.doSaveFunder(nid, restData, true).then(
      (result) => {
        const { funderList, ...restResult } = result;
        dispatch(setGrantFunderAction(funderList));

        return restResult;
      },
      (error) => dispatch(setErrorAction(error.message)),
    );
  };
};
