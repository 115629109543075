export const MSG_PROFILE_NOT_FOUND =
  'There is no user record corresponding to this identifier. The user may have been deleted or in dev mode.';
export const MSG_PROFILE_NO_AGENCY = 'Your email address is not registered with a Fundra account.';
export const MSG_RESET_PWD_SUCCESS = 'Your reset password link was successful send! Please check your email';
export const MSG_REGISTER_SUCCESS = 'Email sent successfully! Please verify your email';
export const MSG_REGISTER_AUTO_SUCCESS = 'Your account was created successfully!';
export const MSG_JOIN_SUCCESS = 'Your account has been successfully activated';
export const MSG_PROFILE_UPDATED = 'Your account information successfully updated.';
export const MSG_PASSWORD_UPDATED = 'Your password successfully updated.';
export const MSG_NONPROFIT_UPDATED = 'Nonprofit information successfully updated.';
export const MSG_PROGRAM_CREATED = 'Program successfully created.';
export const MSG_PROGRAM_UPDATED = 'Program successfully updated.';
export const MSG_PROGRAM_DELETED = 'Program successfully deleted.';
export const MSG_CAMPAIGN_CREATED = 'Campaign successfully created.';
export const MSG_CAMPAIGN_UPDATED = 'Campaign successfully updated.';
export const MSG_CAMPAIGN_DELETED = 'Campaign successfully deleted.';
export const MSG_METRIC_CREATED = 'Activity successfully created.';
export const MSG_METRIC_UPDATED = 'Activity successfully updated.';
export const MSG_METRIC_DELETED = 'Activity successfully deleted.';
export const MSG_OUTCOME_CREATED = 'Outcome successfully created.';
export const MSG_OUTCOME_UPDATED = 'Outcome successfully updated.';
export const MSG_OUTCOME_DELETED = 'Outcome successfully deleted.';
export const MSG_METRIC_CAT_TYPE_CREATED = 'Metric category type successfully created.';
export const MSG_METRIC_CAT_TYPE_UPDATED = 'Metric category type successfully updated.';
export const MSG_METRIC_CAT_TYPE_DELETED = 'Metric category type successfully deleted.';
export const MSG_STRATEGIC_GOAL_CREATED = 'Goal successfully created.';
export const MSG_STRATEGIC_GOAL_UPDATED = 'Goal successfully updated.';
export const MSG_STRATEGIC_GOAL_DELETED = 'Goal successfully deleted.';
export const MSG_STRATEGIC_OBJECTIVE_CREATED = 'Objective successfully created.';
export const MSG_STRATEGIC_OBJECTIVE_UPDATED = 'Objective successfully updated.';
export const MSG_STRATEGIC_OBJECTIVE_DELETED = 'Objective successfully deleted.';
export const MSG_STRATEGIC_CORE_CREATED = 'Core value successfully created.';
export const MSG_STRATEGIC_CORE_UPDATED = 'Core value successfully updated.';
export const MSG_STRATEGIC_CORE_DELETED = 'Core value successfully deleted.';
export const MSG_STRATEGIC_ACTION_PLAN_CREATED = 'Action plan successfully created.';
export const MSG_STRATEGIC_ACTION_PLAN_UPDATED = 'Action plan successfully updated.';
export const MSG_STRATEGIC_ACTION_PLAN_DELETED = 'Action plan successfully deleted.';
export const MSG_STRATEGIC_PLANNING_UPDATED = '%s successfully updated.';
export const MSG_DEPARTMENT_CREATED = 'Department successfully created.';
export const MSG_DEPARTMENT_UPDATED = 'Department successfully updated.';
export const MSG_DEPARTMENT_DELETED = 'Department successfully deleted.';
export const MSG_GRANT_FUNDER_CREATED = 'Funder successfully created.';
export const MSG_GRANT_FUNDER_UPDATED = 'Funder successfully updated.';
export const MSG_GRANT_FUNDER_DELETED = 'Funder successfully deleted.';
export const MSG_TEAM_INVITED = 'Team member successfully invited.';
export const MSG_TEAM_ALREADY_INVITED = 'Team member already invited.';
export const MSG_TEAM_REINVITED = 'Team member successfully reinvited.';
export const MSG_TEAM_DELETED = 'Team member successfully deleted.';
export const MSG_UNSAVED_DATA = 'You have unsaved changes';
export const MSG_UNDO_CHANGE = 'Cancel';
export const MSG_WILL_SAVE_CHANGE = 'Save';
