import {
  STRATEGIC_GOAL_DB_REF,
  STRATEGIC_OBJECTIVES_DB_REF,
  STRATEGIC_PLANNING_DB_REF,
  STRATEGIC_CORE_VALUES_DB_REF,
  STRATEGIC_ACTION_PLAN_DB_REF,
} from '@/global/firebase';

class StrategicProvider {
  constructor(firebaseDatabase, dbPath) {
    this.db = firebaseDatabase;
    this.dbPath = dbPath;

    this.table = {
      goal: STRATEGIC_GOAL_DB_REF,
      objective: STRATEGIC_OBJECTIVES_DB_REF,
      core: STRATEGIC_CORE_VALUES_DB_REF,
      action: STRATEGIC_ACTION_PLAN_DB_REF,
    };
  }

  getDetail = (nid, gid, ref = 'goal') => this.db.ref(`${this.dbPath}/${this.table[ref]}/${nid}/${gid}`).once('value');

  getList = (nid, ref = 'goal') => this.db.ref(`${this.dbPath}/${this.table[ref]}/${nid}`);

  getListOptions = (nid, ref = 'goal') =>
    new Promise((resolve) => {
      this.getList(nid, ref)
        .once('value')
        .then((snapshot) => {
          const options = [];
          snapshot.forEach((childSnapshot) => {
            options.push({ id: childSnapshot.key, name: childSnapshot.val().name });
          });

          if (options.length > 0) {
            options.reverse();
          }

          resolve({ options });
        });
    });

  doSave = async (nid, data, ref = 'goal') => {
    const { key, ...restData } = data;
    let gid = key;

    if (gid) {
      await this.db.ref(`${this.dbPath}/${this.table[ref]}/${nid}/${gid}`).set(restData);
    } else {
      gid = this.db.ref(`${this.dbPath}/${this.table[ref]}/${nid}`).push(restData).key;
    }

    return { ...data };
  };

  doRemove = async (nid, gid, count, ref = 'goal') => {
    const record = await this.db.ref(`${this.dbPath}/${this.table[ref]}/${nid}/${gid}`).once('value');
    if (record.val()) {
      await this.db.ref(`${this.dbPath}/${this.table[ref]}/${nid}/${gid}`).remove();

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };

  // * PLANNING TABLE
  getPlanningDetail = (nid, path) =>
    this.db.ref(`${this.dbPath}/${STRATEGIC_PLANNING_DB_REF}/${nid}/${path}`).once('value');

  doSavePlanning = async (nid, path, data) =>
    this.db.ref(`${this.dbPath}/${STRATEGIC_PLANNING_DB_REF}/${nid}/${path}`).set(data);
}

export default StrategicProvider;
