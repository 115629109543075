import { CAMPAIGN_DB_REF } from '@/global/firebase';
import { CAMPAIGN_COVER_BUCKET, CAMPAIGN_PREFIX, DONATION_WEB_URL, FIREBASE_API_KEY } from '@/global/environment';

import { DateTimeUtils, HttpUtils, StringUtils } from '@/utils';

class CampaignProvider {
  constructor(firebaseDatabase, firebaseStorage, dbPath) {
    this.db = firebaseDatabase;
    this.storage = firebaseStorage;
    this.dbPath = dbPath;
  }

  getDetail = (cid) => this.db.ref(`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}`).once('value');

  getList = (nid) => this.db.ref(`${this.dbPath}/${CAMPAIGN_DB_REF}`).orderByChild('nonprofit/key').equalTo(nid);

  doCreateCampaign = async (data) => {
    const { cover, prevCover, startDate, ...restData } = data;
    const slugName = StringUtils.slugify(data.name);
    let coverUrl = prevCover;

    const campaignKey = DateTimeUtils.getCurrentTimeMs();
    const campaignData = {
      ...restData,
      date: startDate,
      id: campaignKey,
      time: campaignKey,
    };

    await this.db.ref(`${this.dbPath}/${CAMPAIGN_DB_REF}/${campaignKey}`).set(campaignData);

    if (cover) {
      const coverPath = `${CAMPAIGN_COVER_BUCKET}/${campaignKey}.jpg`;
      const coverSnap = await this.storage.child(coverPath).put(cover, { contentType: cover.type });
      coverUrl = await coverSnap.ref.getDownloadURL();

      await this.db.ref(`${this.dbPath}/${CAMPAIGN_DB_REF}/${campaignKey}/cover`).set(coverUrl);
    }

    // create dynamic link
    if (!campaignData.grantCampaign) {
      const campaignInfo = {
        id: campaignKey,
        name: data.name,
        story: data.story,
        cover: coverUrl || '',
        viewUrl: `${DONATION_WEB_URL}/campaigns/${campaignKey}/${slugName}`,
      };
      await this.createDynamicLink(campaignInfo);
    }

    return { ...data, slug: slugName };
  };

  doUpdateCampaign = async (cid, data) => {
    const { cover, prevCover } = data;
    let coverUrl = prevCover;

    const updates = {};
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/goal`] = data.goal;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/location`] = data.location;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/metric`] = data.metric;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/name`] = data.name;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/grantor`] = data.grantor;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/funder`] = data.funder;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/budgeting`] = data.budgeting;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/program`] = data.program;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/story`] = data.story;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/date`] = data.startDate;
    updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/endDate`] = data.endDate;

    if (cover) {
      const coverPath = `${CAMPAIGN_COVER_BUCKET}/${cid}.jpg`;
      const coverSnap = await this.storage.child(coverPath).put(cover, { contentType: cover.type });
      coverUrl = await coverSnap.ref.getDownloadURL();
      updates[`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}/cover`] = coverUrl;
    }

    await this.db.ref().update(updates);

    return { ...data, slug: StringUtils.slugify(data.name), cover: coverUrl };
  };

  doRemoveCampaign = async (cid, count) => {
    const campaign = await this.db.ref(`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}`).once('value');
    if (campaign.val()) {
      await this.db.ref(`${this.dbPath}/${CAMPAIGN_DB_REF}/${cid}`).remove();

      try {
        const coverPath = `${CAMPAIGN_COVER_BUCKET}/${cid}.jpg`;
        await this.storage.child(coverPath).delete();
      } catch (error) {
        console.log(error.message);
      }

      return { status: 'deleted', count };
    }

    return { status: 'not found' };
  };

  createDynamicLink = (campaign) =>
    new Promise((resolve, reject) => {
      const axiosConfig = { headers: { 'Content-Type': 'application/json' } };

      const ENDPOINT_URL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${FIREBASE_API_KEY}`;
      const formData = {
        dynamicLinkInfo: {
          domainUriPrefix: CAMPAIGN_PREFIX,
          link: campaign.viewUrl,
          socialMetaTagInfo: {
            socialTitle: campaign.name,
            socialDescription: campaign.story.replace(/\\r\\n|\\n|\\r/g, ' '),
            socialImageLink: campaign.cover,
          },
        },
        suffix: { option: 'SHORT' },
      };

      HttpUtils.postRequest(ENDPOINT_URL, formData, axiosConfig)
        .then((data) => {
          this.db
            .ref(`${this.dbPath}/${CAMPAIGN_DB_REF}/${campaign.id}/dynamicLink`)
            .set(data.shortLink)
            .then(() => {
              resolve(data);
            });
        })
        .catch((err) => {
          reject(err.message);
        });
    });
}

export default CampaignProvider;
